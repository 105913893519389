@import url('https://fonts.googleapis.com/css2?family=Chewy&family=Fredoka:wght@300..700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #FED420;
}

::selection {
  color: white !important;
  background: #FED420;
}

@font-face {
  font-family: 'Akbar';
  src: url('./fonts/custom.ttf') format('truetype');
}

.shadow-image {
  margin: 0 auto;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
  max-width: 100%;
  height: auto;
}


@keyframes floatAnimation {
  0% {
    transform: translate(-10%, -10%) translateY(0);
  }

  100% {
    transform: translate(-10%, -10%) translateY(-20px);
  }
}

@keyframes floatAnimation2 {
  0% {
    transform: translate(-10%, -10%) translateY(0);
  }

  100% {
    transform: translate(-8%, -8%) translateY(-10px);
  }
}

@keyframes floatAnimation3 {
  0% {
    transform: translate(-0%, -0%) translateY(0);
  }

  100% {
    transform: translate(-0%, -10%) translateY(-2px);
  }
}

.floating-object {
  animation: floatAnimation 5s ease-in-out infinite alternate;
}

.floating-object2 {
  animation: floatAnimation2 5s ease-in-out infinite alternate;
}

.floating-object3 {
  animation: floatAnimation3 5s ease-in-out infinite alternate;
}

/* clouds */
.guess {
  height: 0;
  position: relative;
  width: 100%;
  padding-top: 2%;

  background: #000 !important;

  img {
    width: 250px;
    height: 300px;
    display: block;
    margin: 0 auto;
  }

  .spacing {
    width: 33%;
    text-align: center;
    float: left;
    color: #FED420;
  }

  input {
    display: block;
    margin: 25px auto;
    border: 1px solid #FED420;
    border-radius: 5px;
    height: 25px;
    font-size: 18px;
    width: 250px;
    text-align: center;
    color: #fff;
    background: #181616;
  }



  .buttonwrap {
    text-align: center;
  }

}


/* Animation for the drifting clouds */
@-webkit-keyframes drift {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(100%);
  }
}

@-moz-keyframes drift {
  0% {
    opacity: 1;
    -moz-transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(100%);
  }
}

@-o-keyframes drift {
  0% {
    opacity: 1;
    -o-transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(100%);
  }
}

@-ms-keyframes drift {
  0% {
    opacity: 1;
    -ms-transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -ms-transform: translateX(100%);
  }
}

@keyframes drift {
  0% {
    opacity: 1;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}

.timer-section {
  .timer {
    span {
      color: #000000;
      font-weight: 600;
      font-size: 22px;
      background-color: #FED420;
      padding: 0.5rem;
      margin: 0.5rem;
      border-radius: 4px;
    }
  }
}